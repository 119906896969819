import { ProductVariant } from "@medusajs/medusa"
import Thumbnail from "@modules/products/components/thumbnail"
import Link from "next/link"
import useViewport from "@byq/lib/hooks/use-viewport"

export type ProductHit = {
  id: string
  title: string
  handle: string
  description: string | null
  thumbnail: string | null
  variants: ProductVariant[]
  collection_handle: string | null
  collection_id: string | null
}

export type HitProps = {
  hit: ProductHit
}

const removeHTMLtags = (str) => {
 return str.replace(/<\/?[^>]+(>|$)/g, "");
}


const Hit = ({ hit }: HitProps) => {
  const {width} = useViewport()
  return (
    <div key={hit.id} className="grid grid-cols-[86px_1fr] gap-4 w-full">
      <Thumbnail thumbnail={hit.thumbnail} size="full" />
      <div className="flex flex-col justify-between">
        <div className="flex flex-col">
          {hit.collection_id && (
            <Link href={`/collections/${hit.collection_id}`} legacyBehavior>
              <a className="text-small-regular text-gray-500">
                {hit.collection_handle}
              </a>
            </Link>
          )}
          <span className="text-base-regular">{hit.title}</span>
          { width > 640 &&
            <span className="text-small-regular text-gray-700">
              {removeHTMLtags(hit.description)}
            </span>
          }
        </div>
      </div>
    </div>
  )
}

export default Hit
