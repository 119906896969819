import Footer from "@modules/layout/templates/footer"
import Nav from "@modules/layout/templates/nav/index"
import React from "react"
import Layout from "@byq/layout/Layout"

const Layout2: React.FC = ({ children }) => {
  return (
    <div>
      <Nav />
      <main style={{backgroundColor: "#ffe6da"}} className="relative">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
