const language = "en"

export const tJson = (json) => {
  if(language === "en") {
    return json.item.en.category4
  } else if(language === "hr") {
    return json.item.hr.category4
  } else {
    throw("Unxepected language")
  }
}

export const t = ({en = null, hr = null, item = null}) => {
  if(language === "en") {
    if(item !== null) {
      return item.en
    } else {
      return en
    }
  } else if(language === "hr") {
    if(item !== null) {
      return item.hr
    } else {
      return hr
    }
  } else {
    throw("Unxepected language")
  }
}
