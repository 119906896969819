import { t } from "@byq/i8n"
import UnderlineLink from "@modules/common/components/underline-link"
import Image from "next/legacy/image"

const FooterCTA = () => {
  return (
    <div className="bg-amber-100 w-full">
      <div className="content-container flex flex-col-reverse gap-y-8 small:flex-row small:items-center justify-between py-16 relative">
        <div>
          <h3 className="text-2xl-semi">{t({en: "Shop the latest in Beauty", hr: "Kupujte najnovije proizvode ljepote s Leky"})}</h3>
          <div className="mt-6">
            <UnderlineLink href="/categories/ljepota-sminka">{t({en: "Explore products", hr: "Istražite proizvode"})}</UnderlineLink>
          </div>
        </div>

        <div className="relative w-full aspect-square small:w-[35%] small:aspect-[28/36] mt-[40px]">
          <Image src="/landing_images/girl_shopping.png" width={1600} height={1200} />
        </div>
      </div>
    </div>
  )
}

export default FooterCTA
