import { valuesIn } from "lodash"
import { useMenuDropdown } from "../context/menu-dropdown-context"

export const itemNameToHref = (categories, item) => {
  if("hr" in item.item)  {
    let nameHr = item.item.hr.category4
    if(nameHr === undefined) {
      nameHr = item.item.hr.category3
    }

    if(nameHr === undefined) {
      nameHr = item.item.hr.category2
    }


    if(!nameHr in categories) {
      return "#setmeplease"
    } else {
    }

    const category = categories[nameHr]
    if(category === undefined) return "#setmeplease"

    return category.handle
  } else {
    // TODO: Throw error
    return "#setmeplease"
  }
}

const setMainHref = (categories, mainName) => {
  const txtHr = mainName.hr
  let mainHref = categories[txtHr]
  if(mainHref === undefined) {

    if(txtHr === "Parfemi") {
      mainHref = "ljepota-parfemi-i-mirisi"
    } else if(txtHr === "Njega Kose") {
      mainHref = "njega-kose-"
    }

  } else {
    mainHref = mainHref.handle
  }
  mainHref = "/categories/" + mainHref
  return mainHref
}

const useMenuData = () => {

  const titles = [
    {en: "Perfumes", hr: "Parfemi", nameBackend: "Parfemi i mirisi"},
    {en: "Cosmetics", hr: "Kozmetika", nameBackend: "Šminka"},
    {en: "Hair Care", hr: "Njega Kose", nameBackend: "Njega kose"},
    //{en: "Face Care", hr: "Njega Lica", nameBackend: ""},
    {en: "Body Care", hr: "Njega Kože", nameBackend: "Njega kože"},
    {en: "Shaving and depilation", hr: "Brijanje i depilacija"},
    {en: "Bathing and personal hygiene", hr: "Kupanje i osobna higijena"},
    {en: "beauty-manicure-and-pedicure", hr: "Manikir i pedikir"},
    {en: "Accessories", hr: "Pribor i dodaci"},
  ]
  const {categories} = useMenuDropdown()

  const product_categories = []
  const titlesParsed = []
  for(var category of titles) {

    let {hr, nameBackend} = category

    if(nameBackend === undefined) {
      nameBackend = hr
    }

    const categoryBackend = categories[nameBackend]
    product_categories.push(categoryBackend)
    if(categoryBackend === undefined) continue

    const {handle} = categoryBackend

    const children = categoryBackend.category_children
    const itemsArray = []
    for(var child of children) {
      product_categories.push(child)
      const {handle, name} = child
      const href = "/categories/" + handle
      itemsArray.push({title: name, href: href})
    }
    titlesParsed.push({
      ...category,
      title: category,
      href: "/categories/" + handle,
      subcategories: itemsArray,
    })
  }

  const parsed = {"count":472,"offset":0,"limit":100,"product_categories":product_categories}

  return titlesParsed
}

export default useMenuData
