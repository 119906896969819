import Footer from "@byq/layout/footer"
//import Nav from "@modules/layout/templates/nav/_index"
import Nav from "@byq/layout/nav/index"
import Header from "@byq/layout/nav/Header"
import React from "react"

const Layout: React.FC = ({ children }) => {
  return (
    <div>
      <Header />
      <Nav />
      <main style={{backgroundColor: "#ffe6da"}} className="relative">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
