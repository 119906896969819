import { t } from "@byq/i8n"
import React from "react"

const Header: React.FC = ({ children }) => {
  const style = {
    color: "rgb(106, 72, 80)",

  }
  const classes = "rounded-md col-span-1 text-center p-2 px-4"
  return (
    <div className="bg-red grid grid-flow-col justify-items-center grid-cols-3 py-2 pb-3 sm:text-xs text-l bg-amber-100 text-stone-600">
      <h1 style={style} className={classes}>{t({en: "Croatian support", hr:"Hrvatska podrška"})}</h1>
      <h1 style={style} className={classes}>{t({en: "30 000 beauty products", hr:"30 000 kozmetičkih proizvoda"})}</h1>
        <h1 style={style} className={classes}>{t({en: "Free shipping on orders over 30 euro", hr:"Besplatna dostava za narudžbe iznad 30 eura"})}</h1>
    </div>
  )
}

export default Header
