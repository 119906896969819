import Link from "next/link"
// TODO: Change to svg, so it's harder to copy
export const Logo = () => {
  return (
    <Link legacyBehavior href="/">
    <a style={{fontWeight:400, fontSize: 26, color: "#643810"}} className="pt-[1px] pb-1 text-xl-semi uppercase">Lek<span style={{color: "#b5763c"}}className="">y</span></a>
      </Link>
  )
}

export default Logo
