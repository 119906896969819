import {useMenuDropdown} from "@byq/lib/context/menu-dropdown-context"
import {tJson, t} from "@byq/i8n"
import Link from "next/link"
import useMenuData from "@byq/lib/hooks/use-menu-data"
import clsx from "clsx"


const DropDown = ({subDropdownsData}) => {
  const mainName = subDropdownsData
  const mainHref = subDropdownsData.href
  const {categories} = useMenuDropdown()
  const subDropdowns = []
  const subcategoriesData = subDropdownsData.subcategories

  let i = 0
  let subDropdownsParsed = []
  for(var item of subcategoriesData) {

    subDropdownsParsed.push(
      <div className="" key={i}>
        <Link href={item.href} legacyBehavior>
          <a>{ item.title }</a>
        </Link>
      </div>
    )
    i += 1
  }

  const titleTopCategory = t({en: "Show all in ", hr: "Prikaži sve "}) + t(mainName)

  subDropdownsParsed.push(
    <div key={mainHref}>
      <Link href={mainHref} legacyBehavior>
        <a >{ titleTopCategory }</a>
      </Link>
    </div>)

  return (
    <li className="dropdown  showx">
      <div>

        <span><Link legacyBehavior href={mainHref}><a style={{letterSpacing: "1px"}} > { t(mainName) }</a></Link></span>
        <span className="material-symbols-outlined">
          chevron_right
        </span>
      </div>
      <ul className="menu pb-10">
        <li>
          <a href="#">{ t(mainName) }</a>
        </li>
        <div className={clsx("grid grid-flow-col gap-5 mb-10", {
          "grid-rows-2": subDropdownsParsed.length <= 8,
          "grid-rows-4": subDropdownsParsed.length > 8,
          "grid-rows-6": subDropdownsParsed.length >= 14,
        })}>
          { subDropdownsParsed }
        </div>
      </ul>

    </li>
  )
}


const MegaMenu = () => {
  const titles = useMenuData()
  return (
    <div className="navbar">
      <div className="menu-container content-container">
        <ul className="mega-menu">
          { titles.map(title => {
            return (
              <DropDown key={title.href} subDropdownsData={title} />
          )})}
        </ul>
      </div>
    </div>
  )
}
export default MegaMenu
