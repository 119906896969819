import { t } from "@byq/i8n"
import Logo from "@byq/lib/logo"
import clsx from "clsx"
import { useCollections } from "medusa-react"
import Link from "next/link"
import CountrySelect from "../country-select"

export const openSupport = () => {
  window.$chatwoot.toggle("open");
}


const FooterNav = () => {
  const { collections } = useCollections()

  return (
    <div className="bg-lightbrown">
      <div className="content-container flex flex-col gap-y-8 pt-16 pb-8">
        <div className="flex flex-col gap-y-6 xsmall:flex-row items-start justify-between">
          <div>
            <Link href="/" legacyBehavior>
              <>
                <Logo />
              </>
            </Link>
          </div>
          <div className="text-small-regular grid grid-cols-2 gap-x-16">
            <div className="flex flex-col gap-y-2">

            </div>
            <div className="flex flex-col gap-y-2">
              <span className="text-base-semi">Leky</span>
              <ul className="grid grid-cols-1 gap-y-2">
                <li>
                  <a href="#" onClick={openSupport}>
                    {t({en: "Support", hr: "Podrška"})}
                  </a>
                </li>
                <li>
                  <a href="">
                    {t({en: "About", hr:"O"})} Leky
                  </a>
                </li>
                <li>
                </li>
              </ul>

            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse gap-y-4 justify-center xsmall:items-center xsmall:flex-row xsmall:items-end xsmall:justify-between">
          <span className="text-xsmall-regular text-gray-500">
            © {t({en: "Copyright", hr:"Autorska prava"})} 2023 Leky
          </span>

          <div className="min-w-[316px] flex xsmall:justify-end">
            <CountrySelect />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterNav
