import FooterCTA from "@byq/layout/footer-cta"
import FooterNav from "@byq/layout/footer-nav"
import Header from "@byq/layout/nav/Header"

const Footer = () => {
  return (
    <footer>
      <FooterCTA />
      <FooterNav />
      <Header />
    </footer>
  )
}

export default Footer
