import { useMobileMenu } from "@lib/context/mobile-menu-context"
import Hamburger from "@modules/common/components/hamburger"
import CartDropdown from "@byq/layout/cart-dropdown"
import DropdownMenu from "@byq/layout/dropdown-menu"
import MobileMenu from "@modules/mobile-menu/templates"
import DesktopSearchModal from "@modules/search/templates/desktop-search-modal"
import clsx from "clsx"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import MegaMenu from "@byq/layout/nav/megaMenu"
import { t } from "@byq/i8n"
import Logo from "@byq/lib/logo"


const Nav = () => {
  const { pathname } = useRouter()
  const [isHome, setIsHome] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  //useEffect that detects if window is scrolled > 5px on the Y axis
  useEffect(() => {
    if (isHome) {
      const detectScrollY = () => {
        if (window.scrollY > 5) {
          setIsScrolled(true)
        } else {
          setIsScrolled(false)
        }
      }

      window.addEventListener("scroll", detectScrollY)

      return () => {
        window.removeEventListener("scroll", detectScrollY)
      }
    }
  }, [isHome])

  useEffect(() => {
    pathname === "/" ? setIsHome(true) : setIsHome(false)
  }, [pathname])

  const { toggle } = useMobileMenu()

  return (
    <div
      className={clsx("sticky top-0 inset-x-0 z-50 group", {
        "!fixed": isHome,
      })}
    >

      <header

        className={clsx(
          "bg-lightbrown",
          {
            "": !isHome || isScrolled,
          }
        )}
      >
        {/* <img className="w-[64px]" src="/logo-leky.png"

<a style={{fontWeight:400, fontSize: 25}} className="pt-1 pb-1 text-xl-semi uppercase"><span className="">L</span>eky</a>
        />*/}
        <nav

          className={clsx(
            "text-gray-900 flex items-center justify-between w-full h-full text-small-regular transition-colors duration-200 content-container sm:pl-0",
          )}>
          <div className="flex-1 basis-0 h-full flex items-center ">
            <div className="block small sm:show md:hidden">
              <Hamburger setOpen={toggle} />
            </div>
            <Logo />


            <div className="hidden small:block h-full">

              {/* <DropdownMenu /> */}
            </div>
          </div>
          {/*
          <div className="flex items-center h-full">
          <Link href="/">
          <a className="text-xl-semi uppercase">Leky</a>
          </Link>
          </div>
            */}
          <div className="flex items-center gap-x-6 h-full flex-1 basis-0 justify-end">
            <div className="small:flex items-center gap-x-6 h-full">
              {process.env.FEATURE_SEARCH_ENABLED && <DesktopSearchModal />}
              <div className="sm:hidden">
                <Link href="/account" legacyBehavior>
                  <a>{t({en: "Account", hr: "Račun"})}</a>
                </Link>
              </div>
            </div>
            <CartDropdown />
          </div>
        </nav>
        <div className="sm:hidden">
          <MegaMenu />
        </div>

        <MobileMenu />
      </header>
    </div>
  )
}

export default Nav
