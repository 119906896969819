import { useMobileMenu } from "@lib/context/mobile-menu-context"
import { useStore } from "@lib/context/store-context"
import useCountryOptions from "@lib/hooks/use-country-options"
import ChevronDown from "@modules/common/icons/chevron-down"
import Search from "@modules/common/icons/search"
import X from "@modules/common/icons/x"
import { useCollections, useMeCustomer } from "medusa-react"
import Link from "next/link"
import ReactCountryFlag from "react-country-flag"
import useMenuData from "@byq/lib/hooks/use-menu-data"
import { t, tJson } from "@byq/i8n"
import { useState, useEffect } from "react"

function objMap(obj, func) {
  return Object.entries(obj).map(([k, v]) => func(k, v));
}

const SubMenu = ({menuData}) => {
  return (
    <>
      {menuData.map((item, i) => {
        const title = tJson(item)
        return (
          <li key={i}>
            <Link href={item.href} legacyBehavior>
              <a>{ title }</a>
            </Link>
          </li>
        )
      })}
    </>
  )
}

const MenuRow = ({title, href, close}) => {

  const inner = (
    <button
      className="flex items-center justify-between w-full"
    >
      <span className="sr-only">
        Go to {title} category
      </span>
      <span>{title}</span>
      <ChevronDown className="-rotate-90" />
    </button>
  )
  return (
    <li onClick={close} key={href} className="bg-gray-50 p-4">
      { href
        ? (<Link href={href} legacyBehavior>
             <a>
               {inner}
             </a>
           </Link>)
        : (<>{inner}</>)}
    </li>
  )
}

const CategoriesMenu = ({menuData, setShowBackButton, showBackButton}) => {
  const [subcategoryKey, setSubcategoryKey] = useState(null)
  const subCategory = (data) => {
    setShowBackButton(true)
    setSubcategoryKey(data)
  }

  useEffect(() => {
    if(!showBackButton) {
      setSubcategoryKey(false)
    }
  }, [showBackButton])

  const {
    close,
    screen: [_, setScreen],
  } = useMobileMenu()


  let subcategoryScreen = null
  let topLevelCategory = null
  let subCategoryItems
  if(subcategoryKey !== null && subcategoryKey.key !== undefined) {
    subCategoryItems = menuData[subcategoryKey.key].subcategories
  }
  if(subCategoryItems) {
    const titleShowTopCategory = t({en: "Show all in ", hr: "Prikaži sve "}) + subcategoryKey.title
    subcategoryScreen = (
      <>
        { subCategoryItems.map((item) => {

          let {href, title} = item


          return (
            <MenuRow key={href} close={close} title={title} href={href} />

          )
        }

        )}
        <MenuRow close={close} title={titleShowTopCategory} href={subcategoryKey.href} />
      </>
    )
  } else if(menuData) {
    let index = 0
    topLevelCategory = (
      <>
        {objMap(menuData, (key, value) => {
          index++
          let {href, title} = value

          title = t(title)
          return (
            <div onClick={() => subCategory({key, title, href})}>
              <MenuRow close={() => {}} title={title} />
            </div>
          )
        })}
      </>
    )
  }

  return (
    <ul className="flex flex-col gap-y-2">
      { subcategoryScreen !== null
        ? subcategoryScreen
        : topLevelCategory}
    </ul>


  )
}

const MainMenu = () => {
  const { collections } = useCollections()
  const { customer } = useMeCustomer()
  const { countryCode } = useStore()
  const [showBackButton, setShowBackButton] = useState(false)

  const countries = useCountryOptions()
  let backButton = null
  if(showBackButton) {
    backButton = (
      <div onClick={() => setShowBackButton(false)}>
        <div className="mb-3 ml-3 w-min border-1 border-2 border-slate-300 rounded-lg pl-1 p-2 flex justify-start">
          <ChevronDown className="rotate-90 inline y-2" width={22} height={22} />
          <p className="self-start inline uppercase">{t({en: "Back", hr:"Nazad"})}</p>
        </div>

      </div>
    )
  }

  const {
    close,
    screen: [_, setScreen],
  } = useMobileMenu()

  const setScreenCountry = () => setScreen("country")
  const setScreenSearch = () => setScreen("search")
  const menuData = useMenuData()

  return (
    <div className="flex flex-col flex-1">
      <div className="flex items-center justify-between w-full border-b border-gray-200 py-4 px-6">
        <div className="flex-1 basis-0">
          <button
            className="flex items-center gap-x-2"
            onClick={setScreenCountry}
          >
            <ReactCountryFlag countryCode={countryCode || "hr"} svg />
            <ChevronDown />
          </button>
        </div>
        <div>
          <h1 className="text-xl-semi uppercase">Leky</h1>
        </div>
        <div className="flex-1 basis-0 flex justify-end">
          <button onClick={close}>
            <X size={20} />
          </button>
        </div>
      </div>

      <div className="space-y-6 flex-1 flex flex-col justify-between p-6">
        {process.env.FEATURE_SEARCH_ENABLED && (
          <button
            className="bg-gray-50 flex items-center px-4 py-2 gap-x-2 text-gray-500"
            onClick={setScreenSearch}
          >
            <Search size={24} />
            <span placeholder={t({en:"Delivery", hr: "Dostava"})} className="text-base-regular">
              {t({en: "Search", hr: "Traži"})}
            </span>
          </button>
        )}

        <div className="flex flex-col flex-1 text-large-regular text-gray-900">
          { backButton }
          <CategoriesMenu showBackButton={showBackButton} setShowBackButton={setShowBackButton} menuData={menuData} />
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col gap-y-8 text-small-regular">
            {!customer ? (
              <div className="flex flex-col gap-y-4">
                <span className="text-gray-700 uppercase">{t({en: "Account", hr: "Račun"})}</span>
                <Link href={`/account/login`} passHref legacyBehavior>
                  <a>
                    <button
                      className="flex items-center justify-between border-b border-gray-200 py-2 w-full"
                      onClick={close}
                    >
                      <span className="sr-only">{t({en:"Go to sign in page", hr:"Idite na stranicu za prijavu"})}</span>
                      <span className="normal-case">{t({en:"Sign in", hr:"Prijaviti se"})}</span>
                      <ChevronDown className="-rotate-90" />
                    </button>
                  </a>
                </Link>
              </div>
            ) : (
              <div className="flex flex-col gap-y-4">
                <span className="text-gray-700 uppercase">{t({en:"Signed in as", hr:"Prijavljeni ste kao"})}</span>
                <Link href={`/account`} passHref legacyBehavior>
                  <a>
                    <button
                      className="flex items-center justify-between border-b border-gray-200 py-2 w-full"
                      onClick={close}
                    >
                      <span className="sr-only">{t({en:"Go to account page", hr: "Idi na stranicu računa"})}</span>
                      <span className="normal-case">{customer.email}</span>
                      <ChevronDown className="-rotate-90" />
                    </button>
                  </a>
                </Link>
              </div>
            )}
            <div className="flex flex-col gap-y-4">
              <span className="text-gray-700 uppercase">{t({en:"Delivery", hr: "Dostava"})}</span>
              <button
                className="flex items-center justify-between border-b border-gray-200 py-2"
                onClick={setScreenCountry}
              >
                <span className="sr-only">
                  {t({en:"Click to select shipping country", hr:"Kliknite za odabir zemlje dostave"})}
                </span>
                <div className="flex items-center gap-x-2">
                  <ReactCountryFlag countryCode={countryCode || "hr"} svg />
                  <span className="normal-case">
                    {t({en:"Shipping to", hr: "Dostava do"})}{" "}
                    {countries?.find((c) => c.country === countryCode)?.label}
                  </span>
                </div>
                <ChevronDown className="-rotate-90" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainMenu
